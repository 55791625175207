<template>
  <div class="following-user">
    <div class="following-user__avatar">
      <b-avatar
        :text="currentUser.initials && currentUser.initials || ''"
        :src="currentUser.avatar"
        :to="currentUser.url"
        size="44px"
        class=""
      />
    </div>

    <div class="following-user__main">
      <div class="following-user__user">
        <span class="following-user__font following-user__font--user">
          {{ currentUser.name }}
        </span>
      </div>

      <div class="following-user__username">
        <CLink
          :to="'/' + currentUser.username"
          class="following-user__font following-user__font--username"
        >
          @{{ currentUser.username }}
        </CLink>
      </div>
    </div>

    <div class="following-user__addition">
      <button
        type="button"
        v-if="currentUser.isSubscribed"
        class="following-button"
      >
        <span class="following-button__font" >
          {{ $t('general.subscribed') }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import User from "@/components/models/User";

import CLink from "@/features/ui/CLink.vue";

export default {
  name: "FollowingUser",
  components: { CLink },
  props: {
    user: {
      type: User,
      required: true,
    }
  },
  computed: {
    currentUser() {
      return new User(this.user);
    },
  }
};
</script>

<style scoped lang="scss">
.following-button {
  $parent: &;
  border-radius: em(100, 16);
  border: 1px solid $app-blue;
  padding: em(7, 16) em(15, 16);

  &__font {
    color: $app-blue;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &--edit-profile {
    border: 1px solid $app-purple;
    background-color: $app-purple;
    white-space: nowrap;

    #{$parent} {
      &__font {
        color: $white;
        font-weight: 700;
      }
    }
  }
}

.following-user {
  display: flex;
  flex-wrap: wrap;
  padding: em(10) em(12);
  width: 100%;
  pointer-events: auto;

  &__avatar {
    margin-right: em(11);
  }

  &__main {
    flex-grow: 1;
  }

  &__user {
    justify-content: flex-start;
  }

  &__username {
    margin-top: em(3);
  }

  &__addition {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__font {
    color: $app-black;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &--user {
      color: $app-black;
      font-size: em(14);
      font-weight: 500;
    }

    &--username {
      color: $app-font-color-gray-6;
      font-size: em(12);
    }
  }

  &__icon {
    &--like {
      width: em(13);
      height: em(12);
      color: $app-gray-7;
      transition: color $time-normal $ease, fill $time-normal $ease;

      &--active {
        color: $app-red;
        fill: $app-red;
      }
    }
  }
}
</style>
