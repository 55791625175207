<template>
  <div class="following">
    <PageHeader :title="$t('general.fansPage.title')" />

    <InfinityScroll
      v-if="fans && fans.length"
      :items="fans"
      :has-more="hasMoreFans"
      @rich-end="loadMore"
    >
      <template #default="{ item }">
        <FollowingUser :user="item.user" class="following__user" />
      </template>
    </InfinityScroll>
  </div>
</template>

<script>
import FollowingUser from "@/features/components/FollowingUser.vue";
import PageHeader from "@/layout/PageHeader.vue";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import { mapGetters, mapMutations } from "vuex";
import InfinityScroll from "@/features/components/InfinityScroll.vue";

const postsCallbackType = {
  setFans: "setFans",
  updateFans: "updateFans",
};

export default {
  name: "PageFans",
  components: { InfinityScroll, PageHeader, FollowingUser },
  mixins: [waitRequest, validationError],

  computed: {
    ...mapGetters({
      fans: "fans/fans",
      fansMeta: "fans/fansMeta",
    }),

    hasMoreFans() {
      return Boolean(this.fansMeta?.next_page_url);
    },

    page() {
      return this.fansMeta?.current_page || 1;
    },
  },

  data() {
    return {
      loadFansCallback: {
        [postsCallbackType.setFans]: this.setCurrentPosts,
        [postsCallbackType.updateFans]: this.updateCurrentPosts,
      },
    };
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapMutations({
      setFans: "fans/setFans",
      updateFans: "fans/updateFans",
      setMeta: "fans/setMeta",
    }),

    initState() {
      this.initialLoadFans();
    },

    setCurrentPost(data) {
      this.setFans({ fans: [data] });
    },

    loadFans(successCallbackType = postsCallbackType.setFans) {
      if (this.requestInProgress) return;

      const successCallback = this.loadFansCallback[successCallbackType];
      const requestUrl = "/fans";

      return this.waitRequest(() => {
        return this.$get(
          requestUrl,
          successCallback,
          this.loadPostError,
          this.checkErrors
        );
      });
    },

    async initialLoadFans() {
      this.$showSpinner();

      this.loadFans(postsCallbackType.setFans);
    },

    loadMore() {
      if (this.hasMoreFans)
        return this.loadFans(postsCallbackType.updateFans);
    },

    setCurrentPosts(data) {
      const { current_page, data: fans } = data.subs;

      this.setFans({ fans });
      this.setMeta({ meta: data.subs });

      this.initialPage = current_page;
    },

    updateCurrentPosts(data) {
      const fans = data.subs?.data;

      this.updateFans({ fans });
      this.setMeta({ meta: data.subs });
    },

    loadPostError(errors) {
      console.log(errors);
    },
  },
};
</script>

<style lang="scss" scoped>
.following {
  &__user {
    width: 100%;
    padding: em(15) em(15);
  }
  &__list {
    flex: 1;
  }
}
</style>
